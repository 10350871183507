@keyframes shaking {
    10%, 90% {
        transform: translate3d(-0.2rem, 0, 0);
      }
      
      20%, 80% {
        transform: translate3d(0.2rem, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-0.2rem, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(0.2rem, 0, 0);
      }
}

.animation_shaking{
    animation: shaking 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation-iteration-count: infinite;
}