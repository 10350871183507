

@import './variables.scss';


::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, $blue 14%,$blue 64%);
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, $blue 14%,$blue 64%);
}

::-webkit-scrollbar-track {
    background: $white;
    border-radius: 1rem;
    box-shadow: inset 0rem 0rem 0rem $white;
}

@media all and (orientation: portrait) {
    ::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
        border-radius: 2rem;
    }
    
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(13deg, $blue 14%,$blue 64%);
        border-radius: 0rem;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(13deg, $blue 14%,$blue 64%);
    }
    
    ::-webkit-scrollbar-track {
        background: $white;
        border-radius: 0rem;
        box-shadow: inset 0rem 0rem 0rem $white;
    }
}