﻿//Colors
$blue: #0f1730;
$light_blue: #00aecc;
$orange: #ffb900;
$white: #FFFFFF;
$black: #000000;
$red: #e73737;
$green: #7FD600;
$gray: #d8d8d8;
$light_gray: #d8d8d8;
$dark_gray: #808080;
$light_blue: #f7fafc;

$background_black_opacity: rgba(0, 0, 0, 0.5);
$background_orange_opacity: rgba(255, 185, 0, 0.05);
$background_gray_opacity: rgba(216, 216, 216, 0.12);
$background_blue_opacity: rgba(15, 23, 48, 0.16);

//Font Size
$very_big_title: 8rem;
$big_title: 3rem;
$title: 2rem;
$little_title: 1.8rem;
$normal_text: 1.4rem;
$thin_text: 1.3rem;
$very_thin_text: 1rem;

//Font
$lato : 'Lato', sans-serif;