@import '../variables.scss';

.mat-dialog-information{
    font-size: $normal_text;
    color: $blue;

    .container{
        width: 100%;

        .title{
            font-size: $big_title;
            position: relative;
            width: 100%;
            height: 5rem;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $blue;

            button{
                position: absolute;
                right: 0;
                top: 0;
                
                .iconify{
                    font-size: 4rem;
                }
            }
        }

        .content{

            .information{
                font-size: $little_title;
                margin-bottom: 2rem;
                text-align: center;

                label{
                    font-weight: bolder;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}