.line_split_one {
    width: 100%;

    mat-form-field {
        width: 100%;
    }

    button {
        width: 100%;
        color: $white;
    }
}

.line_split_two {
    display: flex;
    justify-content: space-between;
    width: 100%;

    mat-form-field {
        width: 49%;
    }
}

.font_green{
    color: $green;
}

.background_green{
    background-color: $green;
}

.font_red{
    color: $red;
}

.background_red{
    background-color: $red;
}

.font_dark_gray{
    color: $dark_gray;
}

.background_dark_gray{
    background-color: $dark_gray;
}