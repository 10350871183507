﻿@import './variables.scss';

button {
  border-radius: 3rem;
}

.fat_button {
  height: 6rem;
  width: 20%;
}

.mat-dialog-any-padding .mat-dialog-container {
  padding: 0 !important;
}

.thin_button {
  height: 3rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.mat_form_field_custom {

  .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  .mat-placeholder-required{
    color: $blue !important;
  }
}

.placeholder_gray{
  color: $dark_gray;
}

.mat-option-text{
  padding-left: 2rem;
}