@import '../variables.scss';

.mat-dialog-template{
    font-size: $normal_text;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        margin-bottom: 2rem;

        .title{
            font-size: $title;
            color: $orange
        }

        button{
            .iconify{
                font-size: 5rem;
                color: $blue
            }
        }
    }

    form{
        .content{

        }

        .button_container{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}